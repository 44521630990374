import React from 'react';
import Header from './Header';

function About() {
    return (
        <div>
            <Header />
            <p>This is About page</p>
            <p>This website was made with React</p>
        </div>
    )
}

export default About;