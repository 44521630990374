import React from 'react';

function Header() {
    return (
        <div>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/projects">Projects</a>
            <a href="/contact">Contact</a>
            <hr></hr>
        </div>
    )
}

export default Header;