import React from 'react';
import Header from './Header';

function Home() {
    return (
        <div>
            <Header />
            <h2>Bennetton Ang</h2>
            <p>Just a person doing things</p>
        </div>
    )
}

export default Home;