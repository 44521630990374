import React from 'react';
import Header from './Header';

function Contact() {
    return (
        <div>
            <Header />
            <p>This is Contact page</p>
            <h2>Contact</h2>
            <p>Email: bennettonang[at]gmail.com</p>
            <p>Github: TBA</p>
        </div>
    )
}

export default Contact;